<template>
  <v-container fill-height fluid class="bg">
    <v-container pa-0 fill-height fluid>
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <brand></brand>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="mudadores-title big">Perguntas Frequentes</div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center mb-8">
          <v-form
            @submit.prevent="submit"
            class="container"
            style="max-width: 800px"
          >
            <v-row justify="end">
              <v-col cols="12" class="pa-1">
                <v-text-field
                  v-model="searchInput"
                  autofocus
                  filled
                  dense
                  hide-details
                  placeholder="Encontre sua dúvida"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="2" class="pa-2">
                <v-btn
                  block
                  text
                  color="accent"
                  :disabled="searchInput == ''"
                  @click="
                    () => {
                      searchInput = '';
                      submit();
                    }
                  "
                >
                  <v-icon left>mdi-close</v-icon>Limpar
                </v-btn>
              </v-col>

              <v-col cols="6" md="3" class="pa-2">
                <v-btn type="submit" block rounded color="primary">
                  <v-icon left>mdi-magnify</v-icon>Procurar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          v-if="faqListFiltered.length > 0"
          cols="12"
          class="d-flex justify-center"
        >
          <div style="max-width: 900px; width: 100%">
            <div v-for="(faq, i) in faqList" :key="i">
              <v-expand-transition>
                <div
                  v-show="faqListFiltered.indexOf(faq) > -1"
                  class="q-container"
                  :class="{ open: toggle.indexOf(i) > -1 }"
                  :id="`q-${i}`"
                >
                  <div class="question" @click="onClick(i)">
                    <v-icon
                      class="mr-2 align-self-start"
                      :x-large="!$vuetify.breakpoint.mobile"
                      v-text="toggle.indexOf(i) > -1 ? 'mdi-minus' : 'mdi-plus'"
                      :color="toggle.indexOf(i) > -1 ? 'error' : 'success'"
                    ></v-icon>
                    <span v-html="formatedWithSearch(faq.q)"></span>
                  </div>
                  <v-expand-transition>
                    <div v-show="toggle.indexOf(i) > -1" class="answer">
                      <v-divider class="mb-4"></v-divider>
                      <div v-html="faq.a"></div>
                    </div>
                  </v-expand-transition>
                </div>
              </v-expand-transition>
            </div>
          </div>
        </v-col>
        <v-expand-transition>
          <v-col
            v-if="faqListFiltered.length == 0"
            cols="12"
            class="d-flex justify-center"
          >
            <v-alert type="info" text color="primary">
              Pergunta não encontrada.
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-container>
    <Navbar />
  </v-container>
</template>
<script>
import Navbar from "../components/Navbar";
export default {
  name: "FAQ",
  components: { Navbar },
  data: () => ({
    faqList: [],
    toggle: [],
    hover: -1,
    searchInput: "",
    searchField: "",
  }),
  computed: {
    faqListFiltered: function () {
      if (this.searchField == "" || this.searchField == null)
        return this.faqList;
      return this.faqList.filter((faq) =>
        this.filterObject(faq.q, this.searchField)
      );
    },
  },
  mounted() {
    this.faqList = require("../assets/faq.json");
  },
  methods: {
    onClick: function (index) {
      if (this.toggle.indexOf(index) > -1)
        this.toggle.splice(this.toggle.indexOf(index), 1);
      else this.toggle.push(index);
    },
    filterObject(item, queryText) {
      return (
        this.removeDiacritics(item).indexOf(this.removeDiacritics(queryText)) >
        -1
      );
    },
    removeDiacritics(str) {
      return str
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    submit() {
      this.searchField = this.searchInput;
    },
    formatedWithSearch(str) {
      if (this.searchField == "" || this.searchField == null) return str;
      return str.replace(new RegExp(this.searchField, "gi"), (match) => {
        return '<span class="highlight">' + match + "</span>";
      });
    },
  },
};
</script>
<style lang="scss">
.q-container {
  width: 100%;
  margin-bottom: 32px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0 rgba(black, 0.1);
  padding: 10px 30px;
  color: var(--v-accent-base);
  transition: all 400ms;
}

.q-container.open {
  box-shadow: 0px 4px 14px 0 rgba(black, 0.4);
}

@media only screen and (max-width: 960px) {
  .q-container {
    padding: 10px 6px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 6px 0 rgba(black, 0.3);
  }
}
.q-container .highlight {
  background: var(--v-yellow-base);
}

.question {
  font-size: 22px;
  display: flex;
  align-items: center;
  transition: all 400ms;
  cursor: pointer;
}

.question:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 960px) {
  .question {
    font-size: 18px;
  }
}

.answer {
  opacity: 0.9;
  font-size: 1rem;
  margin-top: 6px;
  padding: 4px 16px;
}
@media only screen and (max-width: 960px) {
  .answer {
    margin-top: 6px;
  }
}
</style>