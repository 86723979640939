<template>
  <div v-if="loggedIn">
    <v-btn color="primary" icon absolute right top @click="drawer = true">
      <v-icon size="32">mdi-menu</v-icon>
    </v-btn>
    <v-navigation-drawer v-model="drawer" fixed temporary right>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 accent--text">
              {{ user.data.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="accent--text">
              {{ user.data.email }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-btn icon color="primary" @click="signOut">
            <v-icon color="red">mdi-exit-to-app</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group active-class="accent--text">
          <v-list-item :to="viewID ? '/?v=' + viewID : '/'">
            <v-list-item-icon>
              <v-icon color="blue">mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="accent--text"
              >Trilha de aprendizagem</v-list-item-title
            >
          </v-list-item>

          <v-list-item :to="'/faq'">
            <v-list-item-icon>
              <v-icon color="blue">mdi-help</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="accent--text">
              Perguntas frequentes
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/certificates'">
            <v-list-item-icon>
              <v-icon color="blue">mdi-certificate-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="accent--text">
              Certificado
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/cadastro'">
            <v-list-item-icon>
              <v-icon color="blue">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="accent--text">
              Dados cadastrais
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/admin'" v-if="user.roles.admin">
            <v-list-item-icon>
              <v-icon color="blue">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="accent--text">
              Área administrativa
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-container style="position: absolute; bottom: 6px; width: 100%">
        <v-row justify="center">
          <v-col class="shrink pa-1">
            <v-btn
              fab
              elevation="0"
              href="https://www.tiktok.com/@escolademudadores"
              target="_blank"
              ><v-img
                max-width="32px"
                contain
                :src="require('../assets/images/tik-tok.png')"
              ></v-img
            ></v-btn>
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              fab
              elevation="0"
              href="https://www.tiktok.com/@escolademudadores"
              target="_blank"
              ><v-img
                max-width="32px"
                contain
                :src="require('../assets/images/youtube.png')"
              ></v-img
            ></v-btn>
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              fab
              elevation="0"
              href="https://www.tiktok.com/@escolademudadores"
              target="_blank"
              ><v-img
                max-width="32px"
                contain
                :src="require('../assets/images/instagram.png')"
              ></v-img
            ></v-btn>
          </v-col>
          <v-col cols="12" class="accent--text text-caption text-center pa-1"
            >Visite nossas redes sociais</v-col
          >
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { signOut } from "../plugins/auth";
export default {
  computed: {
    ...mapGetters(["loggedIn", "user", "viewID"]),
  },
  data: () => ({ drawer: false }),
  methods: {
    async signOut() {
      await signOut();
      this.$router.push("/auth");
    },
  },
};
</script>
